import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Content, PictureFrame } from '../styles/common'

const Synth = () => {

    return (
        <Layout pageTitle='синтезатор и разная музыка'>
            <Content>
                <PictureFrame>
                    <StaticImage src="../images/postRockAlbumCover.jpg" alt='A fallen electrical transmission tower' layout="fullWidth" />
                </PictureFrame>
                <p>I took this picture on a bike ride, the fallen transmission tower hiding in plain sight hit all the right post-rock album art tropes for me, so I will probably use this if I ever record and release something.</p>

                <PictureFrame>
                    <StaticImage src='../images/guitars.jpg' alt='two offset guitars' />
                </PictureFrame>
                <p>A couple of Squier guitars I modified to fit my playing style. I like tinkering with my instruments about as much as I enjoy playing them. Which brings me to...</p>
                <PictureFrame>
                    <StaticImage src='../images/modular.jpg' alt='Eurorack modular synthesizer' />
                </PictureFrame>
                <p>Modular synthesis is pretty much the ultimate tinkering instrument. The majority of the top row in this picture is DIY. As of late I have been doing surface mount soldering with 0805 passives and SOIC package chips. I have a couple modules in my build queue though (a Mutable Instruments Clouds clone, and a Benjolin) that make use of smaller 0603 package passives.</p>
            </Content>
        </Layout>
    )
}

export default Synth
